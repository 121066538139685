html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: 'antialiased';
  -moz-osx-font-smoothing: 'grayscale';
  height: 100%;
  background-color: '#fff';
  font-family: 'Roboto Condensed', sans-serif;
  color: #2D5176;
}

body {
  color: #2D5176;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

a {
  text-decoration: 'none';
}

#root {
  width: '100%';
  height: '100%';
}

.border {
  border: 1px solid black;
}

.separator {
  border-bottom: 1px solid #A5B6C8;
  opacity: 0.25;
}
